import React, { ReactElement } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "./overrides.scss";

interface CarouselProps {
	children: ReactElement[];
	onChange: (index) => void;
}

const Carousel = (props: CarouselProps) => {
	const { children, onChange } = props;
	const handleSlideChange = (swiper: { activeIndex: number }) => {
		onChange(swiper.activeIndex);
	};
	return (
		<Swiper slidesPerView={1} spaceBetween={14} onActiveIndexChange={handleSlideChange}>
			{React.Children.map(children, (child, index) => (
				<SwiperSlide key={index}>{child}</SwiperSlide>
			))}
		</Swiper>
	);
};

export { Carousel };
