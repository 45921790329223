import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import { PersonalityTypeSummary } from "../../molecules/PersonalityTypeSummary";
import { Container } from "../../atoms/Container";
import { Layout } from "../Layout";
import { PersonalityTypes } from "../../../models/PersonalityType";
import { Grid } from "../../atoms/Grid";
import { Typography } from "../../atoms/Typography";
import { StartQuizButton } from "../../atoms/StartQuizButton";
import { Box } from "../../atoms/Box";
import { useMobile } from "../../../hooks/useMobile";
import { Carousel } from "../../atoms/Carousel";
import { Stepper } from "../../molecules/Stepper";
import { theme } from "../../theme";

const useStyles = makeStyles({
	header: {
		[theme.breakpoints.down("sm")]: {
			marginTop: "2.75rem",
			marginBottom: "1.5rem",
		},
		[theme.breakpoints.up("sm")]: {
			marginTop: "4.5rem",
			marginBottom: "4rem",
			fontSize: "2rem",
		},
	},
	startQuizButton: {
		[theme.breakpoints.down("sm")]: {
			marginTop: "2.75rem",
			marginBottom: "3.5rem",
		},
		[theme.breakpoints.up("sm")]: {
			marginTop: "3.25rem",
			marginBottom: "5.25rem",
		},
	},
});

const AllTypesTemplate = () => {
	const styles = useStyles();
	const { isMobile } = useMobile();
	const allTypes = Object.values(PersonalityTypes);
	const [activeSlide, setActiveSlide] = useState(0);

	const renderType = (type: typeof allTypes[number]) => (
		<Grid sm={12} md={6} lg={4} item key={type}>
			<PersonalityTypeSummary dense={true} type={type} />
		</Grid>
	);

	const renderTypeCarousel = (type: typeof allTypes[number]) => (
		<Box height={"100%"} key={type}>
			<PersonalityTypeSummary dense={true} type={type} />
		</Box>
	);

	return (
		<Layout>
			<Container maxWidth={"lg"}>
				<main>
					<Typography
						className={styles.header}
						variant={"h3"}
						component={"h1"}
						align={"center"}
					>
						Poznaj wszystkie typy zawodowe
					</Typography>
					{isMobile ? (
						<Carousel onChange={(activeStepIndex) => setActiveSlide(activeStepIndex)}>
							{allTypes.map(renderTypeCarousel)}
						</Carousel>
					) : (
						<Grid
							container
							direction='row'
							justifyContent='center'
							alignItems='stretch'
							spacing={2}
						>
							{allTypes.map(renderType)}
						</Grid>
					)}
					{isMobile ? (
						<Box marginTop={3}>
							<Stepper
								showCounter={false}
								variant={"dot"}
								steps={allTypes.length}
								activeStepIndex={activeSlide}
							/>
						</Box>
					) : null}

					<Box
						display={"flex"}
						alignItems={"center"}
						justifyContent={"center"}
						className={styles.startQuizButton}
					>
						<StartQuizButton>Zrób test</StartQuizButton>
					</Box>
				</main>
			</Container>
		</Layout>
	);
};

export { AllTypesTemplate };
